import { format } from "date-fns";
import { restaurantClient, GraphQLResponse } from "@/generic/apiClient";
import {
  convertGraphQLError,
  convertAxiosError
} from "@/generic/ErrorConverter";
import {
  CalendarDate,
  Site,
  SharedSeatHasInventories,
  InventoryInput,
  UpdateInventories,
  RefreshInventories,
  SharedInventory,
  SeatGroup,
  SharedInventoryTimeframe,
  DefaultInventory,
  SiteInventory,
  SiteReservationSummary,
  RequestSiteReservationRefresh,
  LinkageEventGroupSet,
  LinkageEventGroupStatus,
  TimelinesAndDefaultInventoriesMap,
  AllocationSettings,
  SiteInventoryUpdateResult
} from "./types";
import axios, { Canceler } from "axios";
import { omit } from "lodash-es";

// せっかくGraphQL使っているので別のリソースとまとめて取得した方が良さそう
export async function getCalendar(
  begin: Date,
  end: Date
): Promise<CalendarDate[]> {
  try {
    const {
      data: { data, errors }
    } = await restaurantClient.post<
      GraphQLResponse<{
        calendar: CalendarDate[];
      }>
    >("/graphql", {
      query: `
            query {
              calendar(
                begin:"${format(begin, "yyyy-MM-dd")}"
                end:"${format(end, "yyyy-MM-dd")}"
              ){
                date
                holiday
                preHoliday
                dayOfWeek
                salesWeekday
              }
            }
          `
    });
    if (errors) throw convertGraphQLError(errors);
    if (!data) throw new Error();
    return data.calendar;
  } catch (e) {
    throw convertAxiosError(e);
  }
}

export async function getSites(restaurantId: number): Promise<Site[]> {
  try {
    const {
      data: { data, errors }
    } = await restaurantClient.post<
      GraphQLResponse<{
        restaurant: {
          sites: Site[];
        };
      }>
    >("/graphql", {
      query: `
              query{
                restaurant(id: ${restaurantId}) {
                  sites {
                    siteType
                    enabled
                    isInventoryLinkedSite
                  }
                }
              }
            `
    });
    if (errors) throw convertGraphQLError(errors);
    if (!data) throw new Error();
    const {
      restaurant: { sites }
    } = data;
    return sites;
  } catch (e) {
    throw convertAxiosError(e);
  }
}

export class SeatRepository {
  private cancelers: Canceler[] = [];

  async fetchSeatsWithInventories(
    restaurantId: number,
    begin: Date,
    end: Date,
    sharedSeatIds: number[]
  ): Promise<{
    sharedSeats: SharedSeatHasInventories[];
    seatGroups: SeatGroup[];
    siteReservationSummaries: SiteReservationSummary[];
    batchUpdateInventoriesRequired: boolean;
  }> {
    const cancelToken = new axios.CancelToken(c => {
      this.cancelers.push(c);
    });

    try {
      const {
        data: { data, errors }
      } = await restaurantClient.post<
        GraphQLResponse<{
          restaurant: {
            batchUpdateInventoriesRequired: boolean;
            sharedSeats: SharedSeatHasInventories[];
            seatGroups: {
              groupId: number;
              name: string;
              builtin: boolean;
              sharedSeats: { sharedSeatId: number }[];
            }[];
            siteReservationSummaries: SiteReservationSummary[];
          };
        }>
      >(
        "/graphql",
        {
          query: `
          query($sharedSeatIds: [Int]!) {
              restaurant(id: ${restaurantId}) {
                batchUpdateInventoriesRequired
                sharedSeats {
                  sharedSeatId
                  name
                  timezone
                  status
                  shared
                  inventories(
                    begin:"${format(begin, "yyyy-MM-dd")}"
                    end:"${format(end, "yyyy-MM-dd")}"
                  ) {
                    salesDate
                    sharedSeatId
                    offered
                    reserved
                    isReleased
                    isSalesSuspended
                    isAdmissionControled
                    isAdmissionRestricted
                    hasOverbooking
                    overbookingReasons
                    serviceTimeRange {
                      begin
                      end
                    }
                    serviceTimeRangeBySeat {
                      begin
                      end
                    }
                    siteInventoryConstraints {
                      siteSeatId
                      salesDate
                      isSalesSuspended
                      salesLimit
                    }
                  }
                  siteSeats {
                    siteSeatId
                    displayId
                    displayRealSeatId
                    name
                    siteType
                    capacity {
                      min
                      max
                    }
                    capacities
                    isCapacitiesEnabled
                    hasSettings
                    maxInventoryCount
                    type
                    typeOtherText
                    attributes {
                      zashiki
                      kotatsu
                      sofa
                      beerGarden
                      coupleSeat
                      kawadoko
                      windowSeat
                      privateTable
                    }
                    connectingType
                    smokingType
                    chargeType
                    linkageStatus {
                      isError
                      code
                      label
                    }
                    isTimeframeInventory
                    isGroupSeat
                    weekdaySettings {
                      weekday
                      priority
                      rotation
                      closed
                      unavailable
                      serviceTimeRange {
                        begin
                        end
                      }
                    }
                    unit
                    weight
                  }
                },
                seatGroups {
                  groupId
                  name
                  builtin
                  sharedSeats {
                    sharedSeatId
                  }
                },
                siteReservationSummaries(
                  begin:"${format(begin, "yyyy-MM-dd")}"
                  end:"${format(end, "yyyy-MM-dd")}"
                  sharedSeatIds: $sharedSeatIds
                ) {
                  salesDate
                  siteType
                  reserved
                  hasOverbooking
                }
              }
            }
          `,
          variables: {
            sharedSeatIds
          }
        },
        {
          cancelToken
        }
      );
      if (errors) throw convertGraphQLError(errors);
      if (!data) throw new Error();

      const {
        restaurant: {
          sharedSeats,
          seatGroups: seatGroupsBase,
          siteReservationSummaries,
          batchUpdateInventoriesRequired
        }
      } = data;

      const seatGroups = seatGroupsBase.map(seatGroup => ({
        ...seatGroup,
        sharedSeats: seatGroup.sharedSeats.map(sharedSeatGrouped =>
          omit(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            sharedSeats.find(
              sharedSeat =>
                sharedSeat.sharedSeatId === sharedSeatGrouped.sharedSeatId
            )!,
            ["inventories", "defaultInventories"]
          )
        )
      }));

      return {
        sharedSeats,
        seatGroups,
        siteReservationSummaries,
        batchUpdateInventoriesRequired
      };
    } catch (e) {
      throw convertAxiosError(e);
    }
  }

  async fetchTimelinesAndDefaultInventories(
    restaurantId: number,
    begin: Date,
    end: Date
  ): Promise<TimelinesAndDefaultInventoriesMap> {
    const cancelToken = new axios.CancelToken(c => {
      this.cancelers.push(c);
    });

    try {
      const {
        data: { data, errors }
      } = await restaurantClient.post<
        GraphQLResponse<{
          restaurant: {
            sharedSeats: {
              sharedSeatId: number;
              inventories: {
                salesDate: string;
                timeline: SharedInventoryTimeframe[];
                siteInventories: SiteInventory[];
              }[];
              defaultInventories: DefaultInventory[];
            }[];
          };
        }>
      >(
        "/graphql",
        {
          query: `
            query {
              restaurant(id: ${restaurantId}) {
                sharedSeats {
                  sharedSeatId
                  inventories(
                    begin:"${format(begin, "yyyy-MM-dd")}"
                    end:"${format(end, "yyyy-MM-dd")}"
                  ) {
                    salesDate
                    timeline {
                      time
                      offered
                      reserved
                      admissions
                      isAdmissionRestricted
                      admissionUpperLimit
                    }
                    hasOverbooking
                    overbookingReasons
                    siteInventories {
                      siteSeatId
                      siteType
                      salesDate
                      offered
                      reserved
                      salesUpperLimit
                      isSalesSuspended
                      isSalesSuspendedBySystem
                      isSalesSuspendedByUser
                      hasOverbooking
                      overbookingReasons
                      hasRotativeReservation
                      isIgnoreDayOff
                      timeline {
                        time
                        admissions
                      }
                      weekdaySetting {
                        weekday
                        rotation
                        closed
                        unavailable
                        serviceTimeRange {
                          begin
                          end
                        }
                      }
                    }
                  }
                  defaultInventories(
                    begin:"${format(begin, "yyyy-MM-dd")}"
                    end:"${format(end, "yyyy-MM-dd")}"
                  ) {
                    salesDate
                    sharedSeatId
                    defaultOffers
                    defaultSuspend
                  }
                }
              }
            }
          `
        },
        {
          cancelToken
        }
      );
      if (errors) throw convertGraphQLError(errors);
      if (!data) throw new Error();

      const {
        restaurant: { sharedSeats }
      } = data;

      const timelinesMap: {
        [sharedSeatId: number]: {
          [salesDate: string]: {
            timeline: SharedInventoryTimeframe[];
            defaultInventory: DefaultInventory;
            siteInventories: SiteInventory[];
          };
        };
      } = {};
      for (const sharedSeat of sharedSeats) {
        timelinesMap[sharedSeat.sharedSeatId] = {};
        for (const inventory of sharedSeat.inventories) {
          timelinesMap[sharedSeat.sharedSeatId][inventory.salesDate] = {
            timeline: inventory.timeline,
            siteInventories: inventory.siteInventories,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            defaultInventory: sharedSeat.defaultInventories.find(
              defaultInventory =>
                defaultInventory.salesDate === inventory.salesDate
            )!
          };
        }
      }
      return timelinesMap;
    } catch (e) {
      throw convertAxiosError(e);
    }
  }

  async fetchAllocationSetting(
    restaurantId: number,
    begin: Date,
    end: Date
  ): Promise<AllocationSettings> {
    try {
      const {
        data: { data, errors }
      } = await restaurantClient.post<
        GraphQLResponse<{
          restaurant: {
            allocationSettings: {
              excludeDates: {
                date: string;
              }[];
            };
          };
        }>
      >("/graphql", {
        query: `
                query{
                  restaurant(id: ${restaurantId}) {
                    allocationSettings {
                      excludeDates(
                        begin:"${format(begin, "yyyy-MM-dd")}"
                        end:"${format(end, "yyyy-MM-dd")}"
                      ){
                        date
                      }
                    }
                  }
                }
              `
      });
      if (errors) throw convertGraphQLError(errors);
      if (!data) throw new Error();
      const {
        restaurant: {
          allocationSettings: { excludeDates }
        }
      } = data;
      return {
        excludeDates: excludeDates.map(excludeDate => excludeDate.date)
      };
    } catch (e) {
      throw convertAxiosError(e);
    }
  }

  async fetchSiteReservationSummaries(
    restaurantId: number,
    begin: Date,
    end: Date,
    sharedSeatIds: number[]
  ): Promise<SiteReservationSummary[]> {
    const cancelToken = new axios.CancelToken(c => {
      this.cancelers.push(c);
    });

    try {
      const {
        data: { data, errors }
      } = await restaurantClient.post<
        GraphQLResponse<{
          restaurant: {
            siteReservationSummaries: SiteReservationSummary[];
          };
        }>
      >(
        "/graphql",
        {
          query: `
            query($sharedSeatIds: [Int]!) {
              restaurant(id: ${restaurantId}) {
                siteReservationSummaries(
                  begin:"${format(begin, "yyyy-MM-dd")}"
                  end:"${format(end, "yyyy-MM-dd")}"
                  sharedSeatIds: $sharedSeatIds
                ) {
                  salesDate
                  siteType
                  reserved
                  hasOverbooking
                }
              }
            }
          `,
          variables: {
            sharedSeatIds
          }
        },
        {
          cancelToken
        }
      );
      if (errors) throw convertGraphQLError(errors);
      if (!data) throw new Error();

      const {
        restaurant: { siteReservationSummaries }
      } = data;
      return siteReservationSummaries;
    } catch (e) {
      throw convertAxiosError(e);
    }
  }

  cancelAll() {
    for (const canceler of this.cancelers) {
      canceler();
    }
    this.cancelers = [];
  }
}

export class InventoryRepository {
  private cancelers: Canceler[] = [];

  async updateInventories(
    restaurantId: number,
    inventories: InventoryInput[],
    dryRun = false,
    batch = true
  ): Promise<SharedInventory[]> {
    const cancelToken = new axios.CancelToken(c => {
      this.cancelers.push(c);
    });

    try {
      const {
        data: { data, errors }
      } = await restaurantClient.post<GraphQLResponse<UpdateInventories>>(
        "/graphql",
        {
          query: `
        mutation($restaurantId: Int!, $inventories: [InventoryInput]!, $batch: Boolean!, $dryRun: Boolean!) {
          updateInventories(dryRun: $dryRun, batch: $batch, restaurantId: $restaurantId, input: $inventories) {
            success
            inventories {
              sharedSeatId
              salesDate
              isAdmissionControled
              isAdmissionRestricted
              hasOverbooking
              siteInventories {
                siteSeatId
                salesDate
                offered
                reserved
                isSalesSuspended
                isSalesSuspendedBySystem
                isSalesSuspendedByUser
                hasOverbooking
              }
            }
          }
        }
      `,
          variables: {
            restaurantId,
            inventories,
            dryRun,
            batch
          }
        },
        { cancelToken }
      );
      if (errors) throw convertGraphQLError(errors);
      if (!data) throw new Error();
      return data.updateInventories.inventories;
    } catch (e) {
      throw convertAxiosError(e);
    }
  }

  async refreshInventories(
    restaurantId: number,
    dryRun = false
  ): Promise<SharedInventory[]> {
    const cancelToken = new axios.CancelToken(c => {
      this.cancelers.push(c);
    });

    try {
      const {
        data: { data, errors }
      } = await restaurantClient.post<GraphQLResponse<RefreshInventories>>(
        "/graphql",
        {
          query: `
        mutation {
          refreshInventories(restaurantId: ${restaurantId}, dryRun: ${dryRun}) {
            success
            inventories {
              sharedSeatId
              salesDate
              isAdmissionControled
              isAdmissionRestricted
              hasOverbooking
              siteInventories {
                siteSeatId
                salesDate
                offered
                reserved
                isSalesSuspended
                isSalesSuspendedBySystem
                isSalesSuspendedByUser
                hasOverbooking
              }
            }
          }
        }
        `
        },
        { cancelToken }
      );
      if (errors) throw convertGraphQLError(errors);
      if (!data) throw new Error();
      return data.refreshInventories.inventories;
    } catch (e) {
      throw convertAxiosError(e);
    }
  }

  cancelAll() {
    for (const canceler of this.cancelers) {
      canceler();
    }
    this.cancelers = [];
  }
}

export async function requestSiteReservationRefresh(
  restaurantId: number
): Promise<boolean> {
  try {
    const {
      data: { data, errors }
    } = await restaurantClient.post<
      GraphQLResponse<RequestSiteReservationRefresh>
    >("/graphql", {
      query: `
        mutation {
          requestSiteReservationRefresh(restaurantId: ${restaurantId}) {
            success
          }
        }
      `
    });
    if (errors) throw convertGraphQLError(errors);
    if (!data) throw new Error();
    return data.requestSiteReservationRefresh.success;
  } catch (e) {
    throw convertAxiosError(e);
  }
}

// 在庫更新FB改善が問題なく動作していることを確認できた段階で削除する
export async function fetchUpdateInventoriesStatus(
  restaurantId: number
): Promise<LinkageEventGroupStatus> {
  try {
    const {
      data: { data, errors }
    } = await restaurantClient.post<
      GraphQLResponse<{
        restaurant: {
          linkageEventGroupSet: LinkageEventGroupSet;
        };
      }>
    >("/graphql", {
      query: `
        query{
          restaurant(id: ${restaurantId}) {
            linkageEventGroupSet {
              siteInventoriesUpdateByOperator {
                status
              }
            }
          }
        }
      `
    });
    if (errors) throw convertGraphQLError(errors);
    if (!data) throw new Error();
    const {
      restaurant: {
        linkageEventGroupSet: {
          siteInventoriesUpdateByOperator: { status }
        }
      }
    } = data;
    return status;
  } catch (e) {
    throw convertAxiosError(e);
  }
}

export async function fetchUpdateInventoriesStatusRemake(
  restaurantId: number
): Promise<SiteInventoryUpdateResult[]> {
  try {
    const {
      data: { data, errors }
    } = await restaurantClient.post<
      GraphQLResponse<{
        restaurant: {
          siteInventoryUpdateResults: SiteInventoryUpdateResult[];
        };
      }>
    >("/graphql", {
      query: `
          query{
            restaurant(id: ${restaurantId}) {
              siteInventoryUpdateResults{
                siteType,
                status,
                error {
                  restaurantId,
                  siteType,
                  status,
                  salesDate,
                  siteRealSeatId,
                  createdAt,
                },
                message,
                detailedViewRequired
              }
            }
          }
        `
    });
    if (errors) throw convertGraphQLError(errors);
    if (!data) throw new Error();
    return data.restaurant.siteInventoryUpdateResults;
  } catch (e) {
    throw convertAxiosError(e);
  }
}

export async function fetchSiteReservationRefreshAllStatus(
  restaurantId: number
): Promise<LinkageEventGroupStatus> {
  try {
    const {
      data: { data, errors }
    } = await restaurantClient.post<
      GraphQLResponse<{
        restaurant: {
          linkageEventGroupSet: LinkageEventGroupSet;
        };
      }>
    >("/graphql", {
      query: `
        query{
          restaurant(id: ${restaurantId}) {
            linkageEventGroupSet {
              siteReservationRefreshAll {
                status
              }
            }
          }
        }
      `
    });
    if (errors) throw convertGraphQLError(errors);
    if (!data) throw new Error();
    const {
      restaurant: {
        linkageEventGroupSet: {
          siteReservationRefreshAll: { status }
        }
      }
    } = data;
    return status;
  } catch (e) {
    throw convertAxiosError(e);
  }
}
